.typewriter-text::before {
  content: "|";
  text-align: left;
  position: absolute;
  background-color: white;
  width: 9em;
  animation: blinkTextCursor 1.5s ease-out infinite alternate;
}

@keyframes blinkTextCursor {
  0% {
    transform: translate(0px);
  }

  100% {
    transform: translate(9em);
  }
}

.typewriter-text-sm::before {
  content: "|";
  text-align: left;
  position: absolute;
  background-color: white;
  width: 4em;
  animation: blinkTextCursorSm 1.5s ease-out infinite alternate;
}

@keyframes blinkTextCursorSm {
  0% {
    transform: translate(0px);
  }

  100% {
    transform: translate(4em);
  }
}

.typewriter-text-md::before {
  content: "|";
  text-align: left;
  position: absolute;
  background-color: white;
  width: 6.5em;
  animation: blinkTextCursorMd 1.5s ease-out infinite alternate;
}

@keyframes blinkTextCursorMd {
  0% {
    transform: translate(0px);
  }

  100% {
    transform: translate(6.5em);
  }
}

.typewriter-text-lg::before {
  content: "|";
  text-align: left;
  position: absolute;
  background-color: white;
  width: 7em;
  animation: blinkTextCursorLg 1.5s ease-out infinite alternate;
}

@keyframes blinkTextCursorLg {
  0% {
    transform: translate(0px);
  }

  100% {
    transform: translate(7em);
  }
}
