.scroll-container {
  animation: scroll-left 53s ease-in-out infinite alternate;
  z-index: 1;
}

.scroll-container:hover,
.scroll-container:active,
.scroll-container:focus {
  animation-play-state: paused;
}

@keyframes scroll-left {
  0% {
    transform: translate(2em);
  }
  100% {
    transform: translate(-122em);
  }
}

@media only screen and (min-width: 768px) {
  .scroll-container {
    animation: scroll-left 45s ease-in-out infinite alternate;
    z-index: 1;
  }
  .scroll-container:hover,
  .scroll-container:active,
  .scroll-container:focus {
    animation-play-state: paused;
  }
  @keyframes scroll-left {
    0% {
      transform: translate(5em);
    }
    100% {
      transform: translate(-150em);
    }
  }
}
